import { Flex, Progress, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import './ProgressBar.less';

export type ProgressBarProps = {
  steps: number;
  totalSteps: number;
};

const ProgressBar = ({ steps, totalSteps = 0 }: ProgressBarProps) => {
  const { t } = useTranslation();
  const progressPercentage = Math.round((steps * 100) / totalSteps);

  return (
    <Flex className={'progress-bar'}>
      <Typography.Text className={'progress-bar-title'}>{t('portal.settings.users.sync.progress')}</Typography.Text>
      <Progress
        percent={progressPercentage}
        strokeColor={'#426da9'}
        size={[150, 20]}
        status="active"
        percentPosition={{ align: 'center', type: 'inner' }}
      />
    </Flex>
  );
};

export default ProgressBar;
