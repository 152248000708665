import { Card } from 'antd';
import PageFooter from '../../../components/layout/PageFooter';
import '../landing/PortalLanding.less';

const SelfService = () => {
  return (
    <div className="content-container">
      <Card>
        <h1>User Self Service</h1>
      </Card>
      <PageFooter />
    </div>
  );
};

export default SelfService;
