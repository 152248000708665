import { SyncOutlined } from '@ant-design/icons';
import { Button, ImprDialog, DialogVariant, ImprTooltip } from '@imprivata-cloud/components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dirSyncProgress, dirFailedSyncFind } from '../../../../api/portalServices';
import { type DirectorySyncProgressGetResponse, SyncStatus, type FailedSyncsFindInfo } from '../../../../api/types';
import { useNotifications } from '../../../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../../../errorHandler/errors';
import { formatDateInUtcWithTz } from '../../../../utils/utils';
import useDirectoriesSync from '../../../hooks/useDirectoriesSync';
import './Sync.less';
import ProgressBar from '../../../../components/progress/ProgressBar';
import CautionIcon from '../../../../assets/icons/caution.svg?react';

export type SyncProps = {
  tenantId?: string;
  directoryId?: string;
  loadUsers?: () => void;
};

const SYNC_PROGRESS_POLL_INTERVAL = 2000; // 2 seconds

export default function Sync({ tenantId, directoryId, loadUsers }: SyncProps) {
  const { t } = useTranslation();
  if (!tenantId || !directoryId) {
    return null;
  }
  const abortControllerRef = useRef<AbortController | undefined>();

  const { emitError, clearErrorNotifications } = useNotifications();
  const [isSyncRunning, setIsSyncRunning] = useState(false);
  const [syncProgressResponse, setSyncProgressResponse] = useState<DirectorySyncProgressGetResponse | undefined>();
  const [isSyncIssuesModalOpen, setIsSyncIssuesModalOpen] = useState(false);
  const [failedSyncs, setFailedSyncs] = useState<FailedSyncsFindInfo[]>();
  const { mutateAsync: runManualSync } = useDirectoriesSync();

  const fetchSyncProgress = useCallback(async () => {
    try {
      clearErrorNotifications();
      const resp = await dirSyncProgress(tenantId, directoryId);
      setSyncProgressResponse(resp);
      const isRunning = resp?.syncStatus === SyncStatus.RUNNING;
      setIsSyncRunning(isRunning);
      if (!isRunning) {
        const failedSyncsResp = await dirFailedSyncFind(tenantId, directoryId);
        setFailedSyncs(failedSyncsResp?.info);
      }
      if (!abortControllerRef.current) {
        return;
      }
      if (isRunning && !abortControllerRef.current?.signal.aborted) {
        setTimeout(() => {
          void fetchSyncProgress();
        }, SYNC_PROGRESS_POLL_INTERVAL);
      }
      if (resp?.syncStatus === SyncStatus.SUCCEEDED && loadUsers && typeof loadUsers === 'function') {
        console.log('sync succeeded, loading users');
        loadUsers();
      }
    } catch (e) {
      emitError(new AppError(e as ApiError));
    }
  }, [clearErrorNotifications, emitError, tenantId, directoryId, loadUsers]);

  const startManualSync = async () => {
    setIsSyncRunning(true);
    runManualSync(tenantId)
      .then(() => {
        void fetchSyncProgress();
      })
      .catch((e) => {
        emitError(new AppError(e as ApiError));
        setIsSyncRunning(false);
        dirFailedSyncFind(tenantId, directoryId)
          .then((failedSyncsResp) => {
            setFailedSyncs(failedSyncsResp?.info);
          })
          .catch(() => {}); // Suppressing the error to keep the original error
      });
  };

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    console.log('sync useEffect, abortController:', abortControllerRef.current);
    if (directoryId) {
      void fetchSyncProgress();
    }
    return () => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = undefined;
    };
  }, [fetchSyncProgress, directoryId]);

  return (
    <div className="sync-container">
      <Button
        type="text"
        label={t('portal.settings.users.sync.update-button')}
        icon={<SyncOutlined />}
        disabled={isSyncRunning}
        onClick={startManualSync}
      />
      {!isSyncRunning && syncProgressResponse?.latestSyncAt && (
        <>
          <span className="update-date">
            {t('portal.settings.users.sync.update-date')}: {formatDateInUtcWithTz(syncProgressResponse?.latestSyncAt)}
          </span>
          {failedSyncs?.length && (
            <>
              <ImprTooltip title={`${failedSyncs.length} ${t('portal.settings.users.sync.issues-tooltip')}`}>
                <Button
                  type="text"
                  icon={<CautionIcon />}
                  onClick={() => {
                    setIsSyncIssuesModalOpen(true);
                  }}
                />
              </ImprTooltip>
              <ImprDialog
                title={t('portal.settings.users.sync.issues-title')}
                open={isSyncIssuesModalOpen}
                width={825}
                destroyOnClose
                onCancel={() => {
                  setIsSyncIssuesModalOpen(false);
                }}
                type={DialogVariant.HEADER_NO_BUTTONS}
                className="sync-issues-modal"
                content={
                  <table>
                    {failedSyncs.map((i) => (
                      <tr key={i.syncId}>
                        <td className="sync-date">{formatDateInUtcWithTz(i.createdAt)}</td>
                        <td>{i.failureReason}</td>
                      </tr>
                    ))}
                  </table>
                }
              />
            </>
          )}
        </>
      )}
      {isSyncRunning && (
        <ProgressBar
          steps={syncProgressResponse?.info?.totalStepsProcessed || 0}
          totalSteps={syncProgressResponse?.info?.totalStepsProcessed || 0}
        />
      )}
    </div>
  );
}
