// Copyright 2025, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, DialogVariant, ImprDialog } from '@imprivata-cloud/components';
import AuthenticatorList from '../../../../components/authenticatorList/AuthenticatorList';
import type { AuthenticatorCardProps } from '../../../../components/authenticatorList/authenticatorCard/AuthenticatorCard';
import { useSession } from '../../../../portal/authorization/context/Session';
import getRelativeTime from '../../../../common/relativeTime';
import { useUserAuthenticators, useUnenrollAuthenticator } from '../hooks/useAuthenticators';
import FaceBioImg from '../../../../assets/icons/face_bio.svg?react';
import QuestionImg from '../../../../assets/icons/question.svg?react';

export interface MenuButtonProps {
  enableMenu: boolean;
  menuIcon?: React.ReactNode;
  menuTitle?: string;
  menuClick?: () => void;
  userId: string;
}

const TRANSLATION_ROOT = 'portal.settings.users.authenticators';

export const RowMenuButton = ({ menuIcon, menuTitle, userId }: MenuButtonProps) => {
  const { tenantId } = useSession();
  const { t } = useTranslation();
  const [authenticatorCards, setAuthenticatorCards] = useState<AuthenticatorCardProps[]>([]);
  const [selectedAuthenticatorId, setSelectedAuthenticatorId] = useState<string | null>(null);
  const [removeDialogVisible, setRemoveDialogVisible] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const { data, isLoading } = useUserAuthenticators(userId, tenantId ?? '', popUpIsOpen);
  const { mutate } = useUnenrollAuthenticator(tenantId ?? '');

  useEffect(() => {
    if (data && popUpIsOpen) {
      setAuthenticatorCards(
        data.map((auth) => ({
          title: auth.factorType === 'face' ? 'face recognition' : auth.factorType,
          icon: <FaceBioImg />,
          subtitle: getRelativeTime(auth.lastUsedAt),
          data: auth,
          onRemove: () => {
            setSelectedAuthenticatorId(auth.authenticatorId);
            setRemoveDialogVisible(true);
            setPopUpIsOpen(false);
          },
        })),
      );
    }
  }, [data, popUpIsOpen]);

  const handleOpenChange = (visible: boolean) => {
    if (visible) {
      setPopUpIsOpen(true);
    } else {
      setPopUpIsOpen(false);
    }
  };

  const handleConfirmRemove = () => {
    if (selectedAuthenticatorId) {
      mutate(selectedAuthenticatorId);
      setRemoveDialogVisible(false);
      setSelectedAuthenticatorId(null);
    }
  };

  const CustomContent = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <QuestionImg style={{ width: '64px', height: '64px', margin: '20px 0' }} />
      <h3>{t(`${TRANSLATION_ROOT}.remove-title`)}</h3>
      <p style={{ marginTop: '5px' }}>{t(`${TRANSLATION_ROOT}.remove-message`)}</p>
    </div>
  );

  return (
    <>
      <ImprDialog
        open={removeDialogVisible}
        type={DialogVariant.HEADER_DUAL_BUTTON}
        width={520}
        height={302}
        submitButtonProps={{
          label: t(`${TRANSLATION_ROOT}.remove`),
          onClick: handleConfirmRemove,
        }}
        cancelButtonProps={{
          label: t(`${TRANSLATION_ROOT}.dont-remove`),
          onClick: () => setRemoveDialogVisible(false),
        }}
        content={<CustomContent />}
        onCancel={() => setRemoveDialogVisible(false)}
      />
      <Popover
        content={
          <AuthenticatorList
            title={t(`${TRANSLATION_ROOT}.title`)}
            cards={authenticatorCards}
            emptyMessage={isLoading ? '' : t(`${TRANSLATION_ROOT}.empty`)}
          />
        }
        title={menuTitle}
        arrow={false}
        trigger="click"
        placement="bottomRight"
        open={popUpIsOpen}
        onOpenChange={handleOpenChange}
      >
        <Button type="text" onClick={() => setPopUpIsOpen(true)} icon={menuIcon} />
      </Popover>
    </>
  );
};

export default RowMenuButton;
