import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { STEPS } from '../../constants';
import { useSaveIdpConfigSAML, useGetIdpConfigSAML } from '../../hooks/useIdpConfigSAML';
import type { AppError } from '../../../errorHandler/errors';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';

const FORM_FIELDS = {
  SAML_ATTRIBUTE_NAME: 'samlAttributeName',
  SAML_ATTRIBUTE_VALUE: 'samlAttributeValue',
};

const ExternalIdpGroups = ({ metadataId }: { metadataId: string }) => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get('tenantId');
  const { t } = useTranslation();
  const TRANSLATION_ROOT = `setup.${STEPS.EXTERNAL_IDP_INFO}`;
  const { emitError, clearErrorNotifications } = useNotifications();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { samlConfig, error, isLoading } = useGetIdpConfigSAML(metadataId, tenantId || '');
  const mutation = useSaveIdpConfigSAML(metadataId, samlConfig?.idpConfigId);

  if (error && (error as AppError).code !== ErrorCode.SECURITY_GROUPS_IDP_ERROR) {
    emitError(error as AppError);
  }

  useEffect(() => {
    if (samlConfig) {
      clearErrorNotifications();
      form.setFieldsValue({
        [FORM_FIELDS.SAML_ATTRIBUTE_NAME]: samlConfig.samlGroupAttrName,
        [FORM_FIELDS.SAML_ATTRIBUTE_VALUE]: samlConfig.samlGroupAttrValues.join(', '),
      });
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [samlConfig, clearErrorNotifications, form]);

  const handleValuesChange = () => {
    const samlAttributeName = form.getFieldValue(FORM_FIELDS.SAML_ATTRIBUTE_NAME)?.trim();
    const samlAttributeValue = form.getFieldValue(FORM_FIELDS.SAML_ATTRIBUTE_VALUE)?.trim();
    setIsButtonDisabled(!samlAttributeName || !samlAttributeValue);
  };

  return (
    <div>
      <p>
        {t(`${TRANSLATION_ROOT}.groups.label`)}
        <a href={'https://docs.imprivata.com/'} target={'_blank'} rel="noreferrer">
          {' '}
          See instructions.
        </a>
      </p>
      <Form form={form} onFinish={(values) => mutation.mutate(values)} onValuesChange={handleValuesChange}>
        {/*SAML Attribute Name */}
        <Form.Item name={FORM_FIELDS.SAML_ATTRIBUTE_NAME} rules={[{ required: true, message: '' }]}>
          <InputBox
            label={t(`${TRANSLATION_ROOT}.groups.saml-attribute-name`)}
            size="large"
            data-testid="saml-attribute-name"
            className={'input'}
          />
        </Form.Item>
        {/*SAML Attribute Value */}
        <Form.Item name={FORM_FIELDS.SAML_ATTRIBUTE_VALUE} rules={[{ required: true, message: '' }]}>
          <InputBox
            label={t(`${TRANSLATION_ROOT}.groups.saml-attribute-value`)}
            size="large"
            data-testid="saml-attribute-value"
            className={'input'}
          />
        </Form.Item>
        <Form.Item style={{ marginTop: '2rem' }}>
          <ContinueButton
            htmlType={'submit'}
            disabled={isLoading || isButtonDisabled}
            validateDirty={[FORM_FIELDS.SAML_ATTRIBUTE_NAME, FORM_FIELDS.SAML_ATTRIBUTE_VALUE]}
          >
            {t('common.continue-button')}
          </ContinueButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ExternalIdpGroups;
