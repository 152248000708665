import { Button, DialogVariant, ImprDialog, ImprTooltip } from '@imprivata-cloud/components';
import { Col, Form, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIdpConfigSAML, idpGet, idpUpdate, updateIdpConfigSAML } from '../../../api/portalServices';
import type { IdpConfigGetResponse, IdpMetadataInfo } from '../../../api/types';
import EditIcon from '../../../assets/icons/edit.svg?react';
import { IntegrationTokenPage } from '../../../common/integration-token/IntegrationTokenPage';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../../errorHandler/errors';
import './Integrations.less';
import IdpInfoForm, { type FormValues, IdpFormFieldNames } from '../../../components/idpInfoForm/IdpInfoForm';
import { getSPMetadataURL } from '../../../utils/utils';
import { withRole } from '../../authorization/Roles';
import { useSession } from '../../authorization/context/Session';
import { ROLE_TYPE_ADMIN } from '../../constants';

const Integrations = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { emitError, clearErrorNotifications } = useNotifications();
  const [idpMetadataInfoList, setIdpMetadataInfoList] = useState<IdpMetadataInfo[]>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialSamlConfig, setInitialSamlConfig] = useState<IdpConfigGetResponse>();
  const [idpForm] = Form.useForm<FormValues>();
  const { tenantId } = useSession();

  const fetchData = useCallback(async () => {
    try {
      clearErrorNotifications();
      setIsLoading(true);
      const idp = await idpGet();
      setIdpMetadataInfoList(idp.idpMetadataInfoList);
      const metadata = idp?.idpMetadataInfoList?.[0];
      if (!metadata) {
        emitError(AppError.fromMessage('Invalid state exception: metadata cannot be empty.'));
        return;
      }
      idpForm.setFieldValue(IdpFormFieldNames.NAME, metadata.idpDisplayName);
      idpForm.setFieldValue(IdpFormFieldNames.URL, metadata.metadataUrl);
      setIsLoading(false);
    } catch (e) {
      emitError(new AppError(e as ApiError));
    }
  }, [idpForm.setFieldValue, emitError, clearErrorNotifications]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  async function closeModal() {
    if (idpForm.isFieldsTouched()) {
      await fetchData();
    }
    setIsModalVisible(false);
  }

  const idpFormOnFinish = async (formValues: FormValues) => {
    const idpMetadataInfo = idpMetadataInfoList?.[0];
    try {
      setIsLoading(true);
      clearErrorNotifications();
      await idpUpdate({
        metadataId: idpMetadataInfo?.idpMetadataId || '',
        displayName: formValues.IdPName,
        metadata: {
          metadataUrl: idpMetadataInfo?.metadataUrl || undefined,
          rawMetadata: formValues.IdPXML || undefined,
        },
      });
      await updateSamlGroups(formValues);
      await closeModal();
    } catch (error) {
      console.log('Failed call idpFormOnFinish', error);
      if (error instanceof AppError) {
        emitError(error);
      } else {
        emitError(AppError.fromMessage('Save failed'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateSamlGroups = async (values: FormValues) => {
    const groupsChanged =
      values?.AdminGroupsName !== initialSamlConfig?.samlGroupAttrName ||
      values?.AdminGroupsValues !== initialSamlConfig?.samlGroupAttrValues.join(', ');
    if (groupsChanged) {
      try {
        const response = await updateIdpConfigSAML(
          {
            idpConfigId: initialSamlConfig?.idpConfigId || '',
            samlGroupAttrName: values.AdminGroupsName || '',
            samlGroupAttrValues: (values.AdminGroupsValues ?? '')
              .split(',')
              .map((value: string) => value.trim())
              .filter((value) => value !== ''),
          },
          tenantId || '',
        );
        console.log('Config updated:', response);
      } catch (error) {
        emitError(error as AppError);
      }
    }
  };

  const handleEditSamlConfig = async () => {
    try {
      const samlConfig = await getIdpConfigSAML(
        { idpMetadataId: idpMetadataInfoList?.[0]?.idpMetadataId || '' },
        tenantId || '',
      );
      idpForm.setFieldValue(IdpFormFieldNames.GROUPS_NAME, samlConfig.samlGroupAttrName);
      idpForm.setFieldValue(IdpFormFieldNames.GROUPS_VALUES, samlConfig.samlGroupAttrValues.join(', '));
      setInitialSamlConfig(samlConfig);
    } catch (error) {
      emitError(error as AppError);
    }
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="integrations-container">
        <Row>
          <Col sm={24} md={24} lg={24} className="setup-content p-xxxl">
            <h2>{t('portal.settings.integrations.header')}</h2>
            <div className="idp-row">
              <div>{idpMetadataInfoList?.[0]?.idpDisplayName}</div>
              <ImprTooltip title={t('portal.settings.integrations.tooltip')}>
                <Button disabled={isLoading} type="text" icon={<EditIcon />} onClick={handleEditSamlConfig} />
              </ImprTooltip>
            </div>
          </Col>
        </Row>
        <div className="divider" />
        <Row className="">
          <Col sm={24} md={24} lg={24} className="setup-content p-xxxl">
            <h2>{t('common.integration-token.page-title')}</h2>
            <IntegrationTokenPage isSetup={false} />
          </Col>
        </Row>
      </div>
      <ImprDialog
        title={t('portal.settings.integrations.modal.title')}
        open={isModalVisible}
        width={825}
        destroyOnClose
        onCancel={closeModal}
        type={DialogVariant.HEADER_NO_BUTTONS}
        content={
          <IdpInfoForm
            form={idpForm}
            spMetadataURL={getSPMetadataURL(tenantId)}
            modalOnClose={closeModal}
            onFinish={idpFormOnFinish}
          />
        }
      />
    </>
  );
};

export default withRole({ authorizedRoles: [ROLE_TYPE_ADMIN] })(Integrations);
