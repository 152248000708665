import { useTranslation } from 'react-i18next';
import { Button } from '@imprivata-cloud/components';
import './AuthenticatorCard.less';
import type { Authenticator } from '../../../api/types';

export interface AuthenticatorCardProps<T = Record<string, unknown>> {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  data: Authenticator;
  onRemove: (cardData: Authenticator) => void;
}

const TRANSLATION_ROOT = 'portal.list-details';

const AuthenticatorCard = ({ icon, title, subtitle, data, onRemove }: AuthenticatorCardProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="list-card">
        <div className="list-card-icon">{icon}</div>
        <div className="list-card-content">
          <span className="list-card-title">{title}</span>
          <span className="list-card-subtitle">{subtitle}</span>
        </div>
        <div className="list-card-actions">
          <Button type="link" onClick={() => onRemove?.(data)} style={{ padding: 0, minWidth: 'auto' }}>
            {t(`${TRANSLATION_ROOT}.remove`)}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AuthenticatorCard;
