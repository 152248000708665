// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import './OrganizationRoute.less';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError, type ApiError } from '../../../errorHandler/errors';
import { setToLocalStorage } from '../../../utils/utils';
import { TRANSLATION_BUTTON, STEPS } from '../../constants';
import { getBase64 } from '../../hooks/useOrganizationInfoSave';
import { StorageKeys, SystemType } from '../../../api/constants';
import { useNavigateToNextStep } from '../../hooks/useNavigateToNextStep';
import LogoUpload from '../../../components/logoUpload/LogoUpload';
import type { RcFile } from 'antd/es/upload';
import * as api from '../../../api/setupServices';
import type { ErrorCode } from '../../../errorHandler/constants';
import { type PrepareFileUploadResponse, FileAccessModifier, type OrgInfoGetResponse } from '../../../api/types';
import { orgInfoGet } from '../../../api/setupServices';

enum FieldNames {
  ORG_NAME = 'orgName',
  EMAIL = 'email',
  LOGO = 'logo',
}

interface FormValues {
  [FieldNames.ORG_NAME]: string;
  [FieldNames.EMAIL]: string;
  [FieldNames.LOGO]: RcFile;
}

const TRANSLATION_ROOT = 'setup.org-info.content';

const OrganizationRoute = () => {
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();
  const navigateToNextStep = useNavigateToNextStep();
  const { emitError } = useNotifications();
  const { t } = useTranslation();
  const { clearErrorNotifications } = useNotifications();
  const [orgInfoGetResponse, setOrgInfoGetResponse] = useState<OrgInfoGetResponse>();

  const fetchData = useCallback(async () => {
    try {
      const orgInfo = await orgInfoGet();
      if (!orgInfo?.orgName) {
        return;
      }
      setOrgInfoGetResponse(orgInfo);
      form.setFieldValue(FieldNames.ORG_NAME, orgInfo.orgName);
      form.setFieldValue(FieldNames.EMAIL, orgInfo.businessEmail);
    } catch (e) {
      emitError(new AppError(e as ApiError));
    }
  }, [form.setFieldValue, emitError]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  const onConfirm = useCallback(async () => {
    try {
      setToLocalStorage(StorageKeys.ORG_EMAIL, form.getFieldValue(FieldNames.EMAIL));

      let uploadLinks: PrepareFileUploadResponse | undefined;

      const logoFile: File = form.getFieldValue(FieldNames.LOGO);
      if (logoFile) {
        uploadLinks = await api.getUploadUrl(SystemType.SETUP, logoFile.name, FileAccessModifier.PUBLIC);
        await api.uploadFile(logoFile, uploadLinks?.uploadUrl || '');
      }

      await api.saveOrgInfo({
        logoUrl: uploadLinks?.readUrl as string,
        orgName: form.getFieldValue(FieldNames.ORG_NAME),
        businessEmail: form.getFieldValue(FieldNames.EMAIL),
        domains: [form.getFieldValue(FieldNames.EMAIL)?.split('@')?.pop()],
      });

      navigateToNextStep(STEPS.SETUP_COMPLETE);
    } catch (error) {
      if (error instanceof AppError) {
        emitError(error);
      }
    }
  }, [form, navigateToNextStep, emitError]);

  async function showConfirmation() {
    const isValid = await validateDomain();
    if (isValid) {
      setIsConfirmation(true);
    }
  }

  function showForm() {
    setIsConfirmation(false);
  }

  const closeBannerIfExists = () => {
    clearErrorNotifications();
  };

  async function validateDomain() {
    try {
      const domain = form.getFieldValue(FieldNames.EMAIL)?.split('@')?.pop();
      const domainRequest = { names: [domain] };
      await api.validateDomain(domainRequest);
      return true;
    } catch (error) {
      const appError = error as AppError;
      const errorCode = `domain-${appError.code}`;
      emitError(new AppError({ code: errorCode as ErrorCode }));
      return false;
    }
  }

  // Rendering this way is important
  // If you remove and then show the form on back to form, you lose is dirty and Continue button gets disabled
  return (
    <>
      {isConfirmation && (
        <ConfirmScreen
          data={form.getFieldsValue()}
          oldImageUrl={orgInfoGetResponse?.logoUrl}
          goBack={showForm}
          onConfirm={onConfirm}
        />
      )}
      <div style={{ display: isConfirmation ? 'none' : 'block' }}>
        <Form form={form} layout="vertical" onFinish={showConfirmation}>
          <div className={'subtitle'} />

          {/* Organization Name */}
          <Form.Item name={FieldNames.ORG_NAME} rules={[{ required: true }]}>
            <InputBox label={t(`${TRANSLATION_ROOT}.name-label`)} required autoFocus />
          </Form.Item>

          <LogoUpload
            fieldName={FieldNames.LOGO}
            fieldValue={form.getFieldValue(FieldNames.LOGO)}
            oldImageUrl={orgInfoGetResponse?.logoUrl}
            isOptional={!!orgInfoGetResponse?.logoUrl}
          />

          {/* Organization Email */}
          <Form.Item
            name={FieldNames.EMAIL}
            extra={t(`${TRANSLATION_ROOT}.email-sub-label`)}
            rules={[{ required: true, type: 'email' }]}
          >
            <InputBox label={t(`${TRANSLATION_ROOT}.email-label`)} required />
          </Form.Item>

          {/* Continue */}
          <ContinueButton
            label={t(TRANSLATION_BUTTON)}
            htmlType="submit"
            onClick={closeBannerIfExists}
            validateDirty={!orgInfoGetResponse?.orgName}
          />
        </Form>
      </div>
    </>
  );
};

const ConfirmScreen = ({
  data,
  oldImageUrl,
  goBack,
  onConfirm,
}: { data: FormValues; oldImageUrl?: string; goBack: () => void; onConfirm: () => void }) => {
  const { t } = useTranslation();
  const TRANSLATION_ROOT = 'setup.org-info-confirm';
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    // no error handling since if this fails, it fails in the LogoUpload
    getBase64(data[FieldNames.LOGO]).then((url) => setImageUrl(url));
  }, [data[FieldNames.LOGO]]);

  console.log('[OrganizationVerify] data:', data);
  return (
    <div>
      <p>{t(`${TRANSLATION_ROOT}.content.label`)}</p>
      <p>{t(`${TRANSLATION_ROOT}.content.name-logo-label`)}</p>
      <h2>{data?.[FieldNames.ORG_NAME]}</h2>
      <img src={imageUrl || oldImageUrl} alt="org logo" />
      <p>{t(`${TRANSLATION_ROOT}.content.domain-name-label`)}</p>
      <h3>{data?.[FieldNames.EMAIL]?.split('@')?.pop()}</h3>
      <Form>
        <ContinueButton
          className="focus-outline"
          autoFocus
          secondaryButton={{ label: t('common.back-button'), onClick: goBack }}
          onClick={onConfirm}
        >
          {t('common.continue-button')}
        </ContinueButton>
      </Form>
    </div>
  );
};

export default OrganizationRoute;
