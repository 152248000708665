// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { Button, type ButtonProps, ImprChip, SearchTagSelect, useAutosuggest } from '@imprivata-cloud/components';
import { Modal } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { findGroups } from '../../../../api/portalServices';
import { type Group, SyncStatus } from '../../../../api/types';
import { useGroups, useMarkGroup, useUnmarkGroupForSync } from '../../../hooks/useGroups';
import { transformSearchPattern } from '../userUtils';
import './GroupsModal.less';

interface GroupsModalProps {
  groupsButtonProps: ButtonProps;
  initSyncStatus?: SyncStatus;
  tenantId: string;
}

const TRANSLATION_ROOT = 'portal.settings.groups';
const extractor = (group: Group) => group.groupName;

const GroupsModal = ({ tenantId, initSyncStatus, groupsButtonProps }: GroupsModalProps) => {
  const { t } = useTranslation();
  const [groupsToUnmark, setGroupsToUnmark] = useState<string[]>([]);
  const markGroup = useMarkGroup();
  const unmarkGroupForSync = useUnmarkGroupForSync();
  const { addedGroups } = useGroups();
  const [isGroupModalVisible, setGroupModalVisible] = useState(false);

  const fetchOptions = useCallback(
    async (searchVal: string) => {
      const availableGroups = await findGroups(
        {
          selectors: {
            search: {
              pattern: transformSearchPattern(searchVal),
              fields: ['name'],
            },
            filters: [{ field: 'sync-enabled', value: 'false', operator: 'equals' }],
          },
        },
        tenantId,
      );
      return availableGroups.groups || [];
    },
    [tenantId],
  );

  const {
    searchValue,
    setSearchValue,
    selected,
    setSelected,
    options,
    selectedObjects: selectedGroups,
  } = useAutosuggest({
    fetchOptions,
    extractor,
  });

  const handleClose = () => {
    setGroupsToUnmark([]);
    setSelected([]);
    setGroupModalVisible(false);
  };

  const handleOk = () => {
    console.log(selectedGroups);
    for (const group of selectedGroups) {
      markGroup.mutate(group.groupId);
    }
    for (const group of groupsToUnmark) {
      unmarkGroupForSync.mutate(group);
    }
    setGroupsToUnmark([]);
    setSelected([]);
    setGroupModalVisible(false);
  };

  const handleRemoveGroup = (group: string) => {
    setGroupsToUnmark([...groupsToUnmark, group]);
  };

  const MenuButton = () => <Button {...groupsButtonProps} onClick={() => setGroupModalVisible(true)} />;

  return (
    <>
      <MenuButton />
      {isGroupModalVisible && (
        <Modal
          destroyOnClose
          title={t(`${TRANSLATION_ROOT}.title`)}
          open={isGroupModalVisible}
          width={800}
          onCancel={handleClose}
          onOk={handleOk}
          className={'groups-modal'}
          maskClosable={false}
        >
          <>
            {initSyncStatus === SyncStatus.RUNNING ? (
              <p>{t(`${TRANSLATION_ROOT}.sync-in-progress`)}</p>
            ) : (
              <div className="groups-modal-content">
                {/* Subtitle and link instructions */}
                <p>
                  {t(`${TRANSLATION_ROOT}.subtitle`)} &emsp;
                  <a href="https://docs.imprivata.com/" target="_blank" rel="noopener noreferrer">
                    {t(`${TRANSLATION_ROOT}.doc-link`)}
                  </a>
                </p>

                {/* Groups already added */}
                <div className="groups-container">
                  {addedGroups
                    .filter((group) => !groupsToUnmark.includes(group.groupId))
                    .map((group) => (
                      <ImprChip
                        key={group.groupId}
                        label={group.groupName}
                        color="processing"
                        closable
                        onClose={() => handleRemoveGroup(group.groupId)}
                      />
                    ))}
                </div>

                {/* Enter new groups */}
                <p className="enter-groups-title">{t(`${TRANSLATION_ROOT}.enter-groups`)}</p>
                <SearchTagSelect
                  antdProps={{ autoFocus: true }}
                  searchValue={searchValue}
                  onSearch={setSearchValue}
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  onBlur={() => {
                    setSearchValue('');
                  }}
                />
              </div>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default GroupsModal;
