import './Settings.less';
import { Tabs } from '@imprivata-cloud/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { featureSupported, getFeatureFlags } from '../../../featureFlagsUtils';
import { withRole } from '../../authorization/Roles';
import { useSession } from '../../authorization/context/Session';
import { ROLE_TYPE_ADMIN } from '../../constants';
import useEntraIdSetupComplete from '../../hooks/useEntraIdSetupComplete';
import Appearance from '../appearance/Appearance';
import Integrations from '../integrations/Integrations';
import Users from '../users/Users';

export const Settings = ({ activeTab }: { activeTab?: string }) => {
  const usersFeatureEnabled = featureSupported('USERS_PAGE');
  const [searchParams] = useSearchParams();
  const { emitError } = useNotifications();
  const { mutate: completeEntraIdSetup, isPending } = useEntraIdSetupComplete();
  const { tenantId } = useSession();
  const { t } = useTranslation();

  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/entraid/auth-success') {
      // entra id microsoft error handling
      if (searchParams.get('error')) {
        emitError(new AppError({ code: ErrorCode.ENTRA_ID_AUTH_ERROR }));
      } else {
        // complete entra id setup
        completeEntraIdSetup(tenantId || '');
      }
    }
  }, [searchParams, emitError, completeEntraIdSetup, tenantId]);

  const tabItems = [];
  console.log('USERS_PAGE:', usersFeatureEnabled);
  if (usersFeatureEnabled) {
    tabItems.push({
      key: 'users',
      label: 'Entra ID Users',
      children: <Users />,
    });
  }
  tabItems.push(
    {
      key: 'integrations',
      label: 'Integrations',
      children: <Integrations />,
    },
    {
      key: 'customize',
      label: 'Customize',
      children: <Appearance />,
    },
  );

  if (isPending) {
    return <div> </div>;
  }

  return (
    <div className="settings-container">
      <div className="settings-header">
        <h1>{t('portal.settings.title')}</h1>
      </div>
      <div className="settings-tab-container">
        <div className="settings-tab-header">
          <Tabs
            tabItems={tabItems}
            type="card"
            size="large"
            data-testid="settings-tabs"
            defaultActiveKey={activeTab || 'users'}
          />
        </div>
      </div>
    </div>
  );
};

export default withRole({ authorizedRoles: [ROLE_TYPE_ADMIN] })(Settings);
