import { type MutableRefObject, useCallback, useEffect, useRef } from 'react';
import type { User } from '../../../../api/types';
import { useUsers } from './useUsers';

interface Args {
  lastElementRef: MutableRefObject<HTMLDivElement | null>;
  searchTerm?: string;
}

type InfiniteScrollType = {
  data?: User[];
  isFetchingNextPage: boolean;
  refetch: () => void;
  isLoading: boolean;
  hasNextPage: boolean;
};

const useInfiniteScroll = ({ lastElementRef, searchTerm }: Args): InfiniteScrollType => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, isLoading } = useUsers(searchTerm || '');

  const observerElem = useRef<IntersectionObserver | null>(null);

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage) {
        void fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage],
  );

  const observer = useCallback(
    (node: HTMLDivElement) => {
      if (isFetchingNextPage) {
        return;
      }
      if (observerElem.current) {
        observerElem.current.disconnect();
      }

      observerElem.current = new IntersectionObserver(observerCallback);
      if (node) {
        observerElem.current.observe(node);
      }
    },
    [isFetchingNextPage, observerCallback],
  );

  useEffect(() => {
    const currentElement = lastElementRef.current;
    if (currentElement) {
      observer(currentElement);
    }
    return () => {
      currentElement && observerElem.current?.unobserve(currentElement);
    };
  }, [lastElementRef, observer]);

  return { data, isFetchingNextPage, refetch, isLoading, hasNextPage };
};

export default useInfiniteScroll;
