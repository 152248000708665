import { Tracer, TracingSolutionType } from '@imprivata-cloud/common';
import { getApiUrl } from '../api/utils';
import { StorageKeys } from '../api/constants';

export let tracer: Tracer;

//  if page is refreshed in the middle of the setup, you keep the workflowId
if (getWorkflowId() && window.location.pathname?.startsWith('/setup')) {
  initTracerSetup(getWorkflowId());
}

export function getWorkflowId(): string {
  return sessionStorage.getItem(StorageKeys.WORKFLOW_ID) || '';
}

export function initTracerSetup(workflowId: string) {
  initTracer('setup-ui', workflowId);
}

export function initTracerPortal(workflowId: string) {
  initTracer('portal-ui', workflowId);
}

export function initTracer(serviceName: string, workflowId: string) {
  const apiUrl = getApiUrl();
  tracer = new Tracer(
    serviceName,
    workflowId,
    {
      logToConsole: true,
      collectSpans: true, // used for local development and CI
      otlpExportUrl: `${apiUrl}/v1/traces`,
    },
    undefined,
    TracingSolutionType.DYNATRACE,
  );
}
