// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as api from '../../api/portalServices';
import { useSession } from '../authorization/context/Session';

export const useGroups = () => {
  const { tenantId = '' } = useSession();

  // Get added groups
  const { data: addedGroupsData } = useQuery({
    queryKey: ['added-groups'],
    queryFn: () =>
      api.findGroups(
        { selectors: { filters: [{ field: 'sync-enabled', value: 'true', operator: 'equals' }] } },
        tenantId,
      ),
    retry: false,
  });
  const addedGroups = addedGroupsData?.groups || [];

  return {
    addedGroups,
  };
};

export const useMarkGroup = () => {
  const queryClient = useQueryClient();
  const { tenantId = '' } = useSession();

  return useMutation({
    mutationFn: (groupId: string) => api.markGroupForSync({ groupId: groupId, isAdmin: false }, tenantId),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['added-groups'] });
    },
  });
};

export const useUnmarkGroupForSync = () => {
  const queryClient = useQueryClient();
  const { tenantId = '' } = useSession();

  return useMutation({
    mutationFn: (groupId: string) => api.unmarkGroupForSync({ groupId: groupId }, tenantId),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['added-groups'] });
    },
  });
};
