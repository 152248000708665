// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useMutation } from '@tanstack/react-query';
import type { GetProp, UploadProps } from 'antd';
import type { SystemType } from '../../api/constants';
import * as api from '../../api/setupServices';
import { FileAccessModifier, type PrepareFileUploadResponse } from '../../api/types';
import type { AppError } from '../../errorHandler/errors';
import { orgPreferencesUpdate } from '../../api/portalServices';

export type OrganizationInfoSaveVarsType = {
  orgName: string;
  usernameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
  logoFile?: File;
  oldLogoUrl?: string;
};

function useOrganizationInfoSave(systemType: SystemType) {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useMutation<any, AppError, OrganizationInfoSaveVarsType>({
    mutationFn: async ({ orgName, usernameLabel, passwordLabel, proxCardLabel, logoFile, oldLogoUrl }) => {
      let uploadLinks: PrepareFileUploadResponse | undefined;

      if (logoFile) {
        uploadLinks = await api.getUploadUrl(systemType, logoFile.name, FileAccessModifier.PUBLIC);
        await api.uploadFile(logoFile, uploadLinks?.uploadUrl || '');
      }

      await orgPreferencesUpdate({
        logoUrl: (uploadLinks?.readUrl || oldLogoUrl) as string,
        orgName: orgName,
        usernameLabel: usernameLabel,
        passwordLabel: passwordLabel,
        proxCardLabel: proxCardLabel,
      });
    },
    onError: (error) => {
      throw error;
    },
  });
}

export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export function getBase64(file: FileType): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => {
      reject(error);
      console.error('Image getBase64 failed.', error, file);
    };
  });
}

export default useOrganizationInfoSave;
