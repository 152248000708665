import { Button, InputBox } from '@imprivata-cloud/components';
import { Form, Modal } from 'antd';
import type { FormInstance } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import QuestionSvg from '../../assets/icons/question.svg?react';
import { STEPS } from '../../setup/constants';
import { useNavigateToNextStep } from '../../setup/hooks/useNavigateToNextStep';
import CopyButton from '../copyButton/copyButton';
import './IdpInfoForm.less';
import type { IdpMetadataInfo } from '../../api/types';

const TRANSLATION_ROOT = `setup.${STEPS.EXTERNAL_IDP_INFO}.content`;
const FILE_INPUT_ID = 'xml-file-input';

export enum IdpFormFieldNames {
  NAME = 'IdPName',
  URL = 'IdPURL',
  XML = 'IdPXML',
  GROUPS_NAME = 'AdminGroupsName',
  GROUPS_VALUES = 'AdminGroupsValues',
}

export interface FormValues {
  IdPName: string;
  IdPURL: string;
  IdPXML: string;
  AdminGroupsName?: string;
  AdminGroupsValues?: string;
}

const IdpInfoForm = ({
  form,
  modalOnClose,
  spMetadataURL,
  onFinish,
  isLoading,
  isSetupSkipAllowed,
  setupExistingIdpMetadata,
}: {
  form: FormInstance;
  // Mandatory for portal. This way we know this is portal, not setup.
  modalOnClose?: () => void;
  spMetadataURL: string;
  onFinish: (values: FormValues) => void;
  isLoading?: boolean;
  isSetupSkipAllowed?: boolean; // Mandatory for setup
  setupExistingIdpMetadata?: IdpMetadataInfo;
}) => {
  const isPortal = !!modalOnClose;
  const isSetupRerun: boolean = !!setupExistingIdpMetadata;
  const { t } = useTranslation();
  const navigateToNextStep = useNavigateToNextStep();

  const [fileName, setFileName] = useState<string>('');
  const [mustConnectModalVisible, setMustConnectModalVisible] = useState(false);

  Form.useWatch(IdpFormFieldNames.URL, form);

  const handleInputBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '') {
      form.setFieldsValue({ IdPXML: '' });
      setFileName('');
    } else {
      setFileName(inputValue);
    }
  };

  const handleInputBoxFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleInputBoxKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      return;
    }
    event.preventDefault();
    if (event.key === 'Backspace' || event.key === 'Delete') {
      form.setFieldsValue({ IdPXML: '' });
      setFileName('');
    }
  };

  useEffect(() => {
    const fileInput = getFileInput();
    // Handle the file input change
    fileInput.onchange = (event) => {
      // Update the file name for display
      const resolve = (name: string, idpXml: string) => {
        form.setFieldsValue({ IdPXML: idpXml });
        setFileName(name);
      };

      // TODO: Handle errors
      const reject = (e: unknown) => {
        console.log('Error:', e);
      };
      handleFileInputChange(event, resolve, reject);
    };
  }, [form.setFieldsValue]);

  const handleFileInputChange = (
    event: Event,
    resolve: (name: string, idpXml: string) => void,
    reject: (error: unknown) => void,
  ) => {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    if (!file) {
      reject(new Error('No file selected.'));
      return;
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'xml') {
      const reader = new FileReader();

      reader.onerror = (e) => {
        console.log('Failed to read the file. Please try again.');
        reject(e);
      };

      reader.onload = () => {
        try {
          const text = reader.result as string;
          const encoder = new TextEncoder();
          const data = encoder.encode(text);
          const idpXml = btoa(String.fromCharCode(...new Uint8Array(data)));

          resolve(file.name, idpXml);
        } catch (error) {
          console.log('There was an issue processing the file. Please try again.');
          reject(error);
        }
      };

      reader.readAsText(file, 'UTF-8');
    } else {
      console.log('Please select an XML file.');
      reject(new Error('Please select an XML file.'));
    }
  };

  return (
    <div className="external-idp-container">
      <Form name="IdpInfo" form={form} layout="vertical" requiredMark={false} onFinish={onFinish}>
        <div className={'idp-subtitle'}>
          {t(`${TRANSLATION_ROOT}.subtitle`)}{' '}
          <a href="https://docs.imprivata.com/TBD" target="_blank" rel="noopener noreferrer">
            {t('common.see-instructions')}
          </a>
        </div>

        {/* Name */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-name-input`)} name={'IdPName'} rules={[{ required: true }]}>
          <InputBox autoFocus size="large" data-testid="idp-name-input" className={'input'} />
        </Form.Item>

        <Trans t={t} i18nKey={`${TRANSLATION_ROOT}.idp-meta-label`} />

        {/* URL */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-url-input`)} name={'IdPURL'} rules={[{ type: 'url' }]}>
          <InputBox size="large" data-testid="idp-url-input" className={'input'} disabled={!!fileName || isPortal} />
        </Form.Item>

        {/* IdP XML file */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-xml-input`)} name={'IdPXML'}>
          <div className="xml-form-item">
            <InputBox
              size="large"
              data-testid="idp-xml-input"
              className={'input'}
              value={fileName}
              onChange={handleInputBoxChange}
              onFocus={handleInputBoxFocus}
              onKeyDown={handleInputBoxKeyDown}
              readOnly={!fileName}
            />
            <Button
              type="text"
              className={'browse-button'}
              label={t(`${TRANSLATION_ROOT}.idp-xml-browse-button`)}
              data-testid="idp-xml-browse-button"
              size="middle"
              onClick={() => getFileInput().click()}
              disabled={!!form.getFieldValue(IdpFormFieldNames.URL) || isSetupRerun}
            />
          </div>
        </Form.Item>

        {/* Copy link */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.sp-metadata-label`)}>
          <div className="link-content">
            <InputBox data-testid="idp-xml-input" className={'input'} value={spMetadataURL} readOnly />
            <CopyButton
              textToCopy={spMetadataURL?.trim()}
              dynamicLabel={{
                initialLabel: t('common.copy'),
                labelAfterClick: t('common.copied'),
                dynamicLabelDuration: 5000,
              }}
            />
          </div>
        </Form.Item>
        {isPortal && (
          <>
            <Form.Item
              name="AdminGroupsName"
              label={t(`${TRANSLATION_ROOT}.admin-groups-name`)}
              rules={[{ required: true }]}
            >
              <InputBox />
            </Form.Item>
            <Form.Item
              name="AdminGroupsValues"
              label={t(`${TRANSLATION_ROOT}.admin-groups-values`)}
              rules={[{ required: true }]}
            >
              <InputBox />
            </Form.Item>
          </>
        )}
        {/* Submit button */}
        <div className={isPortal ? 'idp-form-button-container' : undefined}>
          <Form.Item shouldUpdate>
            {(form) => {
              const values = form.getFieldsValue() as FormValues;
              const isSubmitEnabled = !!(values.IdPName && (isPortal || values.IdPURL || values.IdPXML));
              return (
                <div>
                  <Button
                    size="large"
                    type="primary"
                    block
                    disabled={!isSubmitEnabled || isLoading}
                    htmlType="submit"
                    label={isPortal ? t('common.save') : t('common.continue-button')}
                  />
                </div>
              );
            }}
          </Form.Item>
          <Button
            label={isPortal ? t('common.cancel') : t('common.skip')}
            onClick={() => {
              if (isPortal) {
                modalOnClose?.();
              } else {
                isSetupSkipAllowed ? navigateToNextStep(STEPS.ORG_INFO) : setMustConnectModalVisible(true);
              }
            }}
            size="large"
            style={{
              border: 'none',
              boxShadow: 'none',
              marginTop: '-1rem',
            }}
            block={!isPortal}
            data-testid="skip-button"
          />
        </div>
      </Form>
      <input id={FILE_INPUT_ID} type="file" accept=".xml" hidden />
      <Modal
        open={mustConnectModalVisible}
        destroyOnClose
        footer={null}
        onCancel={() => {
          setMustConnectModalVisible(false);
        }}
      >
        <div className="modal-you-must">
          <div style={{ margin: '.5rem' }}>
            <QuestionSvg style={{ width: '4rem' }} />
          </div>
          <h1>{t('setup.cannot-complete.text')}</h1>
          <div className="footer">
            <Button
              label={t('setup.cannot-complete.button-secondary')}
              data-testid="cannot-complete-btn-secondary"
              onClick={() => {
                window.location.href = 'https://www.imprivata.com';
              }}
            />
            <Button
              label={t('setup.cannot-complete.button-primary')}
              type="primary"
              data-testid="cannot-complete-btn-primary"
              onClick={() => navigateToNextStep(STEPS.START)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IdpInfoForm;

function getFileInput() {
  const fileInput = document.getElementById(FILE_INPUT_ID);
  // To stop IDE from complaining.
  if (!fileInput) {
    throw new Error('XML file input not found');
  }
  return fileInput;
}
