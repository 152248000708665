// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { useMutation, useQuery } from '@tanstack/react-query';
import { getIdpConfigSAML, saveIdpConfigSAML } from '../../api/setupServices';
import { STEPS } from '../constants';
import { useNavigateToNextStep } from './useNavigateToNextStep';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { AppError } from '../../errorHandler/errors';
import { ErrorCode } from '../../errorHandler/constants';

const useSaveIdpConfigSAML = (metadataId: string, idpConfigId?: string) => {
  const navigateToNextStep = useNavigateToNextStep();
  const { emitError } = useNotifications();

  return useMutation({
    mutationFn: async (values: { samlAttributeName: string; samlAttributeValue: string }) => {
      const requestData = {
        idpMetadataId: metadataId,
        idpConfigId: idpConfigId,
        samlGroupAttrName: values.samlAttributeName,
        samlGroupAttrValues: values.samlAttributeValue.split(',').map((item) => item.trim()),
      };
      return saveIdpConfigSAML(requestData);
    },
    onSuccess: (data) => {
      console.log('IdP Config saved:', data.idpConfigId);
      navigateToNextStep(STEPS.ORG_INFO);
    },
    onError: (error) => {
      emitError(new AppError({ code: ErrorCode.INCOMPLETE_OPERATION }));
      console.log('Error saving IdP SAML Config:', error);
    },
  });
};

const useGetIdpConfigSAML = (metadataId: string, tenantId: string) => {
  const {
    data: samlConfig,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['idpConfigSAML', metadataId, tenantId],
    queryFn: () => getIdpConfigSAML({ idpMetadataId: metadataId }, tenantId),
    enabled: !!metadataId,
    retry: false,
  });

  return {
    samlConfig,
    error,
    isLoading,
  };
};

export { useGetIdpConfigSAML, useSaveIdpConfigSAML };
