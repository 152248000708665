// Copyright 2025, Imprivata, Inc.  All rights reserved.

import type { AuthenticatorCardProps } from './authenticatorCard/AuthenticatorCard';
import './AuthenticatorList.less';
import AuthenticatorCard from './authenticatorCard/AuthenticatorCard';

interface AuthenticatorListProps<T> {
  title: string;
  cards: AuthenticatorCardProps[];
  emptyMessage?: string;
}

const AuthenticatorList = <T,>({ title, emptyMessage, cards }: AuthenticatorListProps<T>) => {
  return (
    <div className="list-details">
      {cards.length > 0 ? (
        <div>
          <span className="list-details-title">{title}</span>
          {cards.map((card) => (
            <AuthenticatorCard
              key={card.title}
              icon={card.icon}
              title={card.title}
              subtitle={card.subtitle}
              data={card.data}
              onRemove={card.onRemove}
            />
          ))}
        </div>
      ) : (
        <p className="list-details-empty">{emptyMessage}</p>
      )}
    </div>
  );
};

export default AuthenticatorList;
