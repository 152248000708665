// Copyright 2025, Imprivata, Inc.  All rights reserved.

const getRelativeTime = (timestamp: string) => {
  const now = new Date();
  const past = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  const timeUnits = [
    { unit: 'minute', seconds: 60 },
    { unit: 'hour', seconds: 3600 },
    { unit: 'day', seconds: 86400 },
    { unit: 'month', seconds: 2592000 },
    { unit: 'year', seconds: 31536000 },
  ];

  for (let i = timeUnits.length - 1; i >= 0; i--) {
    const { unit, seconds } = timeUnits[i];
    const interval = Math.floor(diffInSeconds / seconds);
    if (interval >= 1) {
      return `Last used: ${interval} ${unit}${interval !== 1 ? 's' : ''} ago`;
    }
  }
  return 'Last used: just now';
};

export default getRelativeTime;
