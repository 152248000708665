// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Form } from 'antd';
import { useState, useCallback, useEffect } from 'react';
import type { Metadata, IdpMetadataInfo } from '../../../api/types';
import './ExternalIdpInfo.less';
import { StorageKeys } from '../../../api/constants';
import * as api from '../../../api/setupServices';
import IdpInfoForm, { type FormValues, IdpFormFieldNames } from '../../../components/idpInfoForm/IdpInfoForm';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError, type ApiError } from '../../../errorHandler/errors';
import { getSPMetadataURL } from '../../../utils/utils';
import { idpGet } from '../../../api/setupServices';

const ExternalIDPInfo = ({
  setIdpInfoSaved,
  setMetadataId,
}: {
  setIdpInfoSaved: (idpInfoSaved: boolean) => void;
  setMetadataId: (metadataId: string | null) => void;
}) => {
  const [form] = Form.useForm<FormValues>();
  const { emitError, clearErrorNotifications } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [idpMetadataInfo, setIdpMetadataInfo] = useState<IdpMetadataInfo>();

  const fetchData = useCallback(async () => {
    try {
      clearErrorNotifications();
      setIsLoading(true);
      const resp = await idpGet();
      const metadata = resp?.idpMetadataInfoList?.[0];
      if (metadata) {
        form.setFieldValue(IdpFormFieldNames.NAME, metadata.idpDisplayName);
        form.setFieldValue(IdpFormFieldNames.URL, metadata.metadataUrl);
        setIdpMetadataInfo(metadata);
      }
      setIsLoading(false);
    } catch (e) {
      emitError(new AppError(e as ApiError));
    }
  }, [form.setFieldValue, emitError, clearErrorNotifications]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  // Main form submit handler.
  //
  // NOTE: This will be used to display banners on validation errors
  // TODO: we must replace "Throw new Error" and "console.log" with proper UI error handling
  const finishForm = async () => {
    try {
      const values = form.getFieldsValue() as FormValues;

      // Send data to backend
      // TODO: What is the response? Error handling?

      let metadata: Metadata = {
        rawMetadata: values.IdPXML,
      };

      if (values.IdPURL) {
        metadata = {
          metadataUrl: values.IdPURL,
        };
      }

      await api
        .icpMetadataSave({
          metadataId: idpMetadataInfo?.idpMetadataId || '',
          displayName: values.IdPName,
          metadata,
        })
        .then(async (response) => {
          setMetadataId(response.metadataId);
          sessionStorage.setItem(StorageKeys.EXT_IdP_USED, 'true');
          setIdpInfoSaved(true);
        })
        .catch((error) => {
          emitError(new AppError(error));
        });
    } catch (error) {
      if (error instanceof Error) {
        console.log(`Form validation failed: ${error.message}`);
      } else {
        console.log('Unexpected error:', error);
      }
      emitError(new AppError({ code: ErrorCode.UNKNOWN }));
    }
  };

  return (
    <IdpInfoForm
      form={form}
      spMetadataURL={getSPMetadataURL()}
      onFinish={finishForm}
      isLoading={isLoading}
      isSetupSkipAllowed={!!sessionStorage.getItem(StorageKeys.INTEGRATION_TOKEN_USED) || !!idpMetadataInfo}
      setupExistingIdpMetadata={idpMetadataInfo}
    />
  );
};

export default ExternalIDPInfo;
