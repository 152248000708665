export type Metadata = {
  rawMetadata?: string;
  metadataUrl?: string;
};

export interface IdpMetadataGetResponse {
  idpMetadataInfoList: IdpMetadataInfo[];
}

export interface IdpMetadataInfo {
  idpMetadataId: string;
  idpId: string;
  idpDisplayName: string;
  metadataUrl?: string;
}

export interface IdpMetadataSaveRequest {
  metadataId: string;
  displayName: string;
  metadata: Metadata;
}

export interface IdpMetadataUpdateRequest extends IdpMetadataSaveRequest {}

export interface IdpConfigGetResponse {
  idpConfigId: string;
  samlGroupAttrName: string;
  samlGroupAttrValues: string[];
}
export interface IdpConfigGetRequest {
  idpMetadataId: string;
}

export interface IdpConfigSaveRequest {
  idpMetadataId: string;
  samlGroupAttrName: string;
  samlGroupAttrValues: string[];
}

export interface IdpConfigSaveResponse {
  idpConfigId: string;
}

export interface IdpConfigUpdateRequest {
  idpConfigId: string;
  samlGroupAttrName: string;
  samlGroupAttrValues: string[];
}

export interface IdpConfigUpdateResponse {
  idpConfigId: string;
}

export interface OrgInfoGetResponse {
  logoUrl: string;
  orgName: string;
  domains: string[];
  items: string;
  businessEmail: string;
}

// All fields are optional and only the fields that are provided will be updated.
export interface OrgInfoSaveRequest {
  logoUrl?: string;
  orgName?: string;
  domains?: string[];
  businessEmail?: string;
}

export interface OrgPreferencesGetResponse extends OrgPreferencesUpdateRequest {}

export interface OrgPreferencesUpdateRequest {
  logoUrl?: string;
  orgName?: string;
  usernameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
}

export interface DomainsGetResponse {
  names: string[];
}

export interface DomainsSetRequest {
  names: string[];
}

export interface GetAadConsentRequest {
  aadTenantId: string;
}

export interface GetAadConsentResponse {
  aadConsentUrl: string;
}

export interface TenantSetupCompleteRequest {
  email: string;
  withEntraId: boolean;
  consent?: ApiConsent;
}

export interface ApiConsent {
  grantType: string;
  documentName: string;
}

export interface TenantLookupResponse {
  tenantIds: string[];
}

export interface GetClientUserSessionResponse {
  tenantId?: string;
  userInfo?: UserInfo;
}

export type UserInfo = {
  username: string;
  email?: string;
  roleType: string;
  productExperienceVisitorId?: string;
};

export interface EntraIdConsentUrlGetResponse {
  entraConsentUrl: string;
}

export interface EntraIdConsentUrlGetRequest {
  entraTenantId: string;
}

export interface EntraIdTenantSetupResponse {
  entraUserId: string;
}

export type OrgInfo = {
  name: string;
  logoUrl: string;
};

export type StartSsoLink = {
  startSsoUrl: string;
  applianceDesignator: string;
};

export type PurchasedProduct = {
  displayName: string;
  productTag: string;
  startSsoLinks: StartSsoLink[];
};

export type NonPurchasedProduct = {
  displayName: string;
  productTag: string;
  learnMoreUrl: string;
};

export type ProductInfo = {
  purchasedProducts: PurchasedProduct[];
  nonPurchasedProducts: NonPurchasedProduct[];
};

export interface GetLandingPageResponse {
  orgInfo?: OrgInfo;
  productInfo: ProductInfo;
}

export enum FileAccessModifier {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export type PrepareFileUploadResponse = {
  uploadUrl: string;
  readUrl: string;
};

// DPA
export interface CheckDpaConsentRequest {
  consentDocumentName: string;
}
export interface CheckDpaConsentResponse {
  actionType: CheckDpaConsentResponseActionType;
}
export enum CheckDpaConsentResponseActionType {
  NONE = 'NONE', // Don't show the clickthrough
  CHECK_NEEDED = 'CHECK_NEEDED',
  UPDATE_NEEDED = 'UPDATE_NEEDED',
}

// create token
export interface ClientCreateRequest {
  enterpriseId: string;
  installationTokenExpirationInMinutes: number;
}

export interface ClientCreateResponse {
  clientMetadata: ClientMetadata;
}

export type ClientMetadata = {
  clientId: string;
  eamEnterpriseId: string;
  installationToken: TokenSecret;
  tokenExpiryTime: number;
  createdAt: number;
  installationTokenValue: string;
};
export type TokenSecret = {
  data: string;
  origin: null;
  isProxied: false;
};

export type GroupRequest = {
  groupName: string;
  groupId: string;
  userCount: number;
  syncEnabled: boolean;
  adminEnabled: boolean;
  idpDeleted: boolean;
};

export type FindDirectoriesResponse = {
  directories: Directory[];
};

export type Directory = {
  name: string;
  directoryId: string;
  latestSyncAt: string;
  syncedGroupCount: number;
  idpTenantId: string;
};

export type ListSelectors = {
  fields?: string[];
  sortFields?: SortField[];
  filters?: Filter[];
  search?: SearchSelector;
};

export type SortField<T = string> = {
  field: T;
  order?: SortOrder;
};

export type Filter = {
  field: string;
  value?: string | boolean | number;
  operator?: FilterOperator;
};

export type SearchSelector<T = string> = {
  pattern: string;
  fields?: T[];
};

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FilterOperator {
  EQUALS = 'equals',
  NOT_EQUALS = 'not-equals',
  NOT_NULL = 'not-null',
}

export const ALL_ITEMS_PATTERN = '*';

export type FindUsersRequest =
  | {
      selectors: ListSelectors;
      pageSize?: number;
    }
  | {
      pageToken: string;
      pageSize?: number;
    };

export type DirectorySyncProgressGetRequest = {
  directoryId: string;
};

export type DirectorySyncProgressGetResponse = {
  syncStatus: SyncStatus;
  failureReason?: SyncFailureReason;
  info?: SyncProgressInfo;
  latestSyncAt?: string;
};

export enum SyncStatus {
  NOT_INITIALIZED = 'not-initialized',
  RUNNING = 'running',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum SyncFailureReason {
  IDP_FAILURE = 'idp-failure',
  SYNC_ALREADY_RUNNING = 'sync-already-running',
  SYNC_INTERRUPTED = 'sync-interrupted',
  SYSTEM_FAILURE = 'system-failure',
  TRANSIENT_IDP_FAILURE = 'transient-idp-failure',
  TRANSIENT_SYSTEM_FAILURE = 'transient-system-failure',
}

export type SyncProgressInfo = {
  directoryId: string;
  syncId: string;
  totalStepsProcessed: number;
  totalSteps: number;
};

export type DirectoryFailedSyncFindRequest = {
  directoryId: string;
};

export type DirectoriesFailedSyncFindResponse = {
  info: FailedSyncsFindInfo[];
};

export type FailedSyncsFindInfo = {
  syncId: string;
  failureReason: string;
  createdAt: string;
  endedAt?: string;
};

export type FindUsersResponse = {
  users: User[];
  totalCount: number;
  latestUpdatedAt: string;
  pagingInfo?: PagingInfo;
};

export type User = {
  userId: string;
  displayName: string;
  upn: string;
};

export type PagingInfo = {
  afterToken: string;
  beforeToken: string;
};

export interface GroupForSyncMarkRequest {
  groupId: string;
  isAdmin: boolean;
}

export interface GroupForSyncUnmarkRequest {
  groupId: string;
}

export interface GroupsFindResponse {
  groups: Group[];
  totalCount: number;
  latestUpdatedAt: string;
  pagingInfo: PagingInfo;
}

export interface Group {
  groupName: string;
  groupId: string;
  userCount: number;
  syncEnabled: boolean;
  adminEnabled: boolean;
  idpDeleted: boolean;
}

export interface FindListRequest {
  selectors: {
    search?: {
      pattern: string;
      fields: string[];
    };
    filters: {
      field: string;
      value: string;
      operator: string;
    }[];
  };
}

export interface DomainsValidateRequest {
  names: string[];
}

export interface AuthenticatorsFindByUserIdsRequest {
  userIds: string[];
}

export interface AuthenticatorsFindByUserIdsResponse {
  authenticators: Authenticator[];
}

export interface AuthenticatorsUnenrollRequest {
  authenticatorIds: string[];
}

export interface Authenticator {
  authenticatorId: string;
  userId: string;
  shortName: string;
  lastUsedAt: string;
  factorType: string;
  assuranceType: string;
}
