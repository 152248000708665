import moment from 'moment';
import { _getUrl } from '../api/utils';

export const setToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

// Used to turn the path into a key for translations and graphics
//
// TODO: Fix this monstrosity
export const getPathKey = (route: string) => {
  if (route.length === 0) {
    return '';
  }

  let updatedRoute = route;

  if (updatedRoute.endsWith('/')) {
    updatedRoute = updatedRoute.slice(0, -1);
  }

  return updatedRoute.slice(1).replaceAll('/', '.');
};

export const getSPMetadataURL = (tenantIdParam?: string) => {
  const tenantId = tenantIdParam
    ? tenantIdParam
    : new URLSearchParams(window.location.search).get('tenantId') || 'no-tenant-id';
  const urls = _getUrl({
    spMetadataUrl: { sub: 'metadata.app', path: `/${tenantId}/saml_metadata/sp_saml_metadata.xml` },
  });
  return urls?.spMetadataUrl;
};

export const getPortalUrl = () => {
  return `https://${window.location.host}`;
};

export const getUrlParam = (param: string): string | null => {
  return new URLSearchParams(window.location.search).get(param);
};

export const getIDPUrl = (tenantId: string, workflowId: string) => {
  const urls = _getUrl({
    idpUrl: { sub: `${tenantId}.sys`, path: '/idp-web/idp/authenticate' },
  });
  const idpUrl = urls?.idpUrl;
  const targetUrl = getUrlParam('target') || getPortalUrl();
  const idpQueryParams = `?workflowId=${workflowId}&target=${targetUrl}`;
  return `${idpUrl}${idpQueryParams}`;
};

export const getSSOLogoutUrl = (tenantId: string, workflowId: string) => {
  const urls = _getUrl({
    ssoUrl: { sub: `${tenantId}.sys`, path: '/idp-web/idp/start-slo' },
  });
  const idpUrl = urls?.ssoUrl;
  const targetUrl = getUrlParam('target') || getPortalUrl();
  const idpQueryParams = `?workflowId=${workflowId}&target=${targetUrl}`;
  return `${idpUrl}${idpQueryParams}`;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.debug('Copied to clipboard');
    })
    .catch((e) => {
      console.error(`Copy to clipboard FAILED!: ${text}. E: `, e);
    });
};

// from 1970-01-01T00:00:00Z
// to   1970-01-01 00:03:00 UTC+0300
export function formatDateInUtcWithTz(date?: string) {
  if (!date) {
    return null;
  }

  return moment(date).format('YYYY-MM-DD HH:mm:ss [UTC]ZZ');
}
