// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { findAuthenticatorsByUserIds, unenrollAuthenticators } from '../../../../api/portalServices';

export const useUserAuthenticators = (userId: string, tenantId: string, isOpen: boolean) => {
  return useQuery({
    queryKey: ['authenticators', userId],
    queryFn: async () => {
      const response = await findAuthenticatorsByUserIds({ userIds: [userId] }, tenantId);
      return response.authenticators;
    },
    enabled: Boolean(userId && tenantId && isOpen),
    retry: false,
  });
};

export const useUnenrollAuthenticator = (tenantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (authnId: string) => {
      await unenrollAuthenticators({ authenticatorIds: [authnId] }, tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['authenticators'] });
    },
    retry: false,
  });
};
