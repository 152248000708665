import { useInfiniteQuery } from '@tanstack/react-query';
import { findUsers } from '../../../../api/portalServices';
import { SortOrder } from '../../../../api/types';
import { useSession } from '../../../authorization/context/Session';
import { UserFieldNames, transformSearchPattern } from '../userUtils';

export const useUsers = (searchValue: string) => {
  const pattern = transformSearchPattern(searchValue);
  const tenantId = useSession().tenantId;

  return useInfiniteQuery({
    queryKey: ['findUsers'],
    queryFn: async ({ pageParam }) => {
      if (pageParam === undefined) {
        return { users: [], pagingInfo: { afterToken: pageParam } };
      }
      const payload = {
        pageSize: 25,
        ...(pageParam
          ? { pageToken: pageParam }
          : {
              selectors: {
                search: {
                  pattern,
                  fields: [UserFieldNames.DISPLAY_NAME, UserFieldNames.UPN],
                },
                sortFields: [{ field: UserFieldNames.DISPLAY_NAME, order: SortOrder.ASC }],
              },
            }),
      };
      return findUsers(tenantId || '', payload);
    },
    initialPageParam: '',
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.pagingInfo?.afterToken === '') {
        return undefined;
      }
      return lastPage.pagingInfo?.afterToken;
    },
    select: ({ pages }) => pages.flatMap((page) => page.users),
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: Number.POSITIVE_INFINITY,
  });
};
