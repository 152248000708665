// Copyright 2024, Imprivata, Inc.  All rights reserved.

export const Endpoints = {
  AAD_CONSENT_URL: '/am-portal-setup-svc/tenant-setup/v1/aad-consent-url/get',
  COMPLETE_SETUP: '/am-portal-setup-svc/tenant-setup/v1/complete',
  DIR_INITIAL_SYNC: '/am-portal-svc/directory/v1/initial-sync/start',
  DIR_FAILED_SYNC_FIND: '/am-portal-svc/directory/v1/directories/failed-sync/find',
  DIR_FIND: '/am-portal-svc/directory/v1/directories/find',
  DIR_SYNC: '/am-portal-svc/directory/v1/directories/sync',
  DIR_SYNC_PROGRESS: '/am-portal-svc/directory/v1/directories/sync-progress/get',
  DOMAINS_GET: '/am-portal-svc/tenant-service/v1/domains/get',
  DOMAINS_UPDATE: '/am-portal-svc/tenant-service/v1/domains/update',
  DPA_CHECK: '/am-portal-setup-svc/user-consent/v2/consent/dpa-check',
  ENTRA_ID_CONSENT: '/am-portal-svc/entra-id/v1/entra-id-consent-url/get',
  ENTRA_ID_SETUP_COMPLETE: '/am-portal-svc/entra-id/v1/entra-id/setup',
  FIND_AUTHENTICATORS: '/am-portal-svc/authenticators/v1/find/by-user-ids',
  GET_CLIENT_USER_SESSION: '/am-portal-svc/session/v1/client-user-session/get',
  GET_GROUPS: '/am-portal-svc/identity-group/v1/groups/find',
  GET_LANDING_PAGE: '/am-portal-svc/v2/landing-page/get',
  GET_IDP_CONFIG_SAML: '/am-portal-svc/icp-config-admin/v1/idp-config-saml/get',
  GET_IDP_CONFIG_SAML_SETUP: '/am-portal-setup-svc/icp-config-admin/v1/idp-config-saml/get',
  GROUPS_FIND: '/am-portal-svc/identity-group/v1/groups/find',
  IDP_GET: '/am-portal-svc/icp-metadata-admin/v1/idp/get',
  IDP_UPDATE: '/am-portal-svc/icp-metadata-admin/v1/idp/update',
  ICP_METADATA_SAVE: '/am-portal-setup-svc/icp-metadata-admin/v1/idp/save',
  IP_RANGES: '/am-portal-svc/entra-id/v1/entra-id-config/get',
  LOGOUT: '/idp-web/idp/logout',
  MARK_GROUP_SYNC: '/am-portal-svc/identity-group/v1/group-for-sync/mark',
  ORG_INFO_GET: '/am-portal-setup-svc/organization-info/v1/get',
  ORG_INFO_SAVE: '/am-portal-setup-svc/organization-info/v1/save',
  PORTAL_CONNECT_CLIENT_CREATE: '/am-portal-svc/connect-eamc/v1/client/create',
  PORTAL_ORG_PREFERENCES_GET: '/am-portal-svc/preferences/v1/org-preferences/get',
  PORTAL_ORG_PREFERENCES_UPDATE: '/am-portal-svc/preferences/v1/org-preferences/update',
  PORTAL_PREPARE_FILE_UPLOAD: '/am-portal-svc/file-access/v1/prepare-file-upload',
  PREPARE_FILE_UPLOAD: '/am-portal-setup-svc/file-access/v1/prepare-file-upload',
  SAVE_IDP_CONFIG_SAML: '/am-portal-setup-svc/icp-config-admin/v1/idp-config-saml/save',
  SETUP_CONNECT_CLIENT_CREATE: '/am-portal-setup-svc/connect-eamc/v1/client/create',
  SETUP_IDP_GET: '/am-portal-setup-svc/icp-metadata-admin/v1/idp/get',
  START_TENANT_SETUP: '/am-portal-setup-svc/tenant-setup/v1/start',
  TENANT_LOOKUP_BY_DOMAIN: '/am-portal-svc/tenant-lookup/v1/tenants-by-domain/get',
  UNENROLL_AUTHENTICATORS: '/am-portal-svc/authenticators/v1/unenroll',
  UNMARK_GROUP_SYNC: '/am-portal-svc/identity-group/v1/group-for-sync/unmark',
  UPDATE_IDP_CONFIG_SAML: '/am-portal-svc/icp-config-admin/v1/idp-config-saml/update',
  USERS_FIND: '/am-portal-svc/user/v1/users/find',
  DOMAINS_VALIDATE: '/am-portal-setup-svc/tenant-service/v1/domains/validate',
} as const;

export enum SystemType {
  SETUP = 'SETUP',
  PORTAL = 'PORTAL',
}

export const CommonHeaders = {
  'Content-Type': 'application/json',
  'Strict-Transport-Security': 'max-age=86400;includeSubDomains',
  'X-XSS-Protection': '1;mode=block',
  'X-Content-Type-Options': 'nosniff',
  'X-Frame-Options': 'DENY',
};

export const IdpChoices = {
  ENTRA_ID_VALUE: 'entraId',
  EXTERNAL_VALUE: 'external',
  NONE_VALUE: 'none',
} as const;

export const StorageKeys = {
  WORKFLOW_ID: 'ImprWorkflowId',
  TENANT_SETUP_STATE: 'tenantSetupState', // type SetupState
  DPA_STATUS: 'DPA_STATUS', // type DpaStatus
  ORG_EMAIL: 'orgEmail',
  INTEGRATION_TOKEN_USED: 'INTEGRATION_TOKEN_USED', // type boolean
  EXT_IdP_USED: 'EXT_IdP_USED', // type boolean
};

export enum SetupState {
  STARTED = 'started',
  COMPLETED = 'completed',
}

export enum DpaStatus {
  GRANTED = 'GRANTED',
}
