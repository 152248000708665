// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { useNavigateToNextStep } from './useNavigateToNextStep';
import { sendTenantSetupCompleteRequest } from '../../api/setupServices';
import { ErrorCode } from '../../errorHandler/constants';
import type { ApiError } from '../../errorHandler/errors';
import { FatalError } from '../../errorHandler/errors';
import { STEPS } from '../constants';

const useCompleteSetupMutation = () => {
  const navigateToNextStep = useNavigateToNextStep();

  return useMutation({
    mutationFn: (withEntraId: boolean) => sendTenantSetupCompleteRequest(withEntraId),
    onSuccess: () => {
      console.log('Setup completed successfully');
      navigateToNextStep(STEPS.SETUP_COMPLETE);
    },
    onError: (error: ApiError) => {
      console.error('Error completing the setup:', error);
      throw new FatalError({ code: ErrorCode.FATAL_ERROR });
    },
  });
};

export default useCompleteSetupMutation;
