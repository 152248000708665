// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { lookupTenant } from '../../api/portalServices';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { AppError } from '../../errorHandler/errors';
import { getIDPUrl } from '../../utils/utils';

const useTenantLogin = () => {
  const { emitError } = useNotifications();

  return useMutation({
    mutationFn: async ({ email, workflowId }: { email: string; workflowId?: string }) => {
      return lookupTenant(email, workflowId);
    },
    onSuccess: (response, { email, workflowId }) => {
      if (response?.tenantIds?.length > 0) {
        const idpUrl = getIDPUrl(response.tenantIds[0], workflowId || '');
        window.location.href = idpUrl;
      }
    },
    onError: (e) => {
      if (e instanceof AppError) {
        emitError(e);
      }
    },
  });
};

export default useTenantLogin;
