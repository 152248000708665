// Copyright 2024, Imprivata, Inc.  All rights reserved.
// NOTE: This is temporary until feature flags have been properly implemented
import { _getEnv } from './api/utils';
import featureFlags from './featureFlags.json';

export type FeatureFlags = typeof featureFlags;

enum ENVIRONMENT {
  local = 1,
  dev = 2,
  int = 3,
  eph = 4,
  stg = 5,
  prod = 6,
}

export type FeatureEnvironment = keyof typeof ENVIRONMENT;

export type FeatureConfig = {
  PENDO_API_KEY: string;
  USERS_PAGE?: boolean;
};

export function featureSupported(feature: keyof FeatureConfig): boolean {
  const env = _getEnv();
  let featureEnv: FeatureEnvironment = 'prod';

  if (ENVIRONMENT[env as keyof typeof ENVIRONMENT]) {
    featureEnv = env as FeatureEnvironment;
  }
  const currentLevel = ENVIRONMENT[featureEnv];
  const featureLevel = Object.keys(ENVIRONMENT)
    .filter((key) => Number.isNaN(Number(key)))
    .find((key) => {
      const envFeatures = featureFlags[key.toLowerCase() as keyof FeatureFlags];
      return (
        feature in envFeatures &&
        typeof envFeatures[feature as keyof typeof envFeatures] === 'boolean' &&
        envFeatures[feature as keyof typeof envFeatures]
      );
    });

  console.debug('[featureFlagUtils] feature flags: ', {
    featureEnv,
    currentLevel,
    featureLevel,
  });

  if (featureLevel) {
    return currentLevel <= ENVIRONMENT[featureLevel as keyof typeof ENVIRONMENT];
  }

  return false;
}

export function getFeatureFlags() {
  const currentEnv = _getEnv();
  let features = featureFlags['prod' as keyof FeatureFlags];

  if (Object.keys(featureFlags).includes(currentEnv)) {
    features = featureFlags[currentEnv as keyof FeatureFlags];
  }

  console.debug('[featureFlagUtils] feature flags: ', {
    currentEnv,
    features,
  });
  return features;
}
