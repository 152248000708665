import type { ButtonProps } from '@imprivata-cloud/components';
import { Flex, Spin } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { dirSyncProgress, findDirectories } from '../../../api/portalServices';
import type { Directory, SyncStatus } from '../../../api/types';
import MenuIcon from '../../../assets/icons/kabob_menu.svg?react';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import type { AppError } from '../../../errorHandler/errors';
import { useSession } from '../../authorization/context/Session';
import ImprListView from './ImprListView/ImprListView';
import UsersEmptyPage from './UsersEmptyPage';
import GroupsModal from './groups/GroupsModal';
import useInfiniteScroll from './hooks/useInfiniteScroll';
import Sync from './sync/Sync';

const Users = () => {
  const { tenantId } = useSession();
  const [directories, setDirectories] = useState<Directory[]>();
  const { emitError } = useNotifications();
  const [initSyncStatus, setInitSyncStatus] = useState<SyncStatus>();
  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const [searchVal, setSearchVal] = useState<string>('');

  const {
    data: userList = [],
    isFetchingNextPage,
    refetch,
    isLoading,
  } = useInfiniteScroll({
    lastElementRef,
    searchTerm: searchVal,
  });

  const handleSearch = () => {
    refetch();
  };

  const handleSearchChange = (searchValue: string) => {
    setSearchVal(searchValue);
  };

  const loadUsers = useCallback(async () => {
    console.log('[Users] loadUsers');
    refetch();
  }, [refetch]);

  const getSyncProgress = useCallback(async () => {
    const resp = await dirSyncProgress(tenantId || '', directories ? directories[0]?.directoryId : '');
    setInitSyncStatus(resp?.syncStatus);
  }, [tenantId, directories]);

  useEffect(() => {
    try {
      findDirectories(tenantId ?? '').then((response) => {
        const directories = response?.directories;
        setDirectories(directories);
      });
    } catch (e) {
      console.error('Error fetching directories:', e);
      emitError(e as AppError);
    }
  }, [tenantId, emitError]);

  useEffect(() => {
    //check initial sync progress if no synced groups
    if (directories && directories[0]?.syncedGroupCount === 0) {
      void getSyncProgress();
    }
  }, [directories, getSyncProgress]);

  const groupsButtonProps: ButtonProps = {
    icon: <MenuIcon />,
    size: 'small',
    style: { borderColor: 'transparent', boxShadow: 'none', marginLeft: 'auto' },
  };

  if (isLoading) {
    return (
      <Flex justify={'center'} style={{ padding: 15 }}>
        <Spin data-testid={'loading-indicator'} size="small" />
      </Flex>
    );
  }

  return (
    <>
      <div>
        <ImprListView
          enableMenu={true}
          menuIcon={<MenuIcon />}
          dataSource={userList}
          customEmptyListNode={<UsersEmptyPage directories={directories} initSyncStatus={initSyncStatus} />}
          sortLabel={'All users (by name)'}
          handleSearch={handleSearch}
          onSearchChange={handleSearchChange}
          rowKey={'upn'}
          loading={isLoading}
          headerExtraComponents={[
            <>
              {directories?.[0]?.directoryId ? (
                <>
                  <Sync
                    key={'manualSync'}
                    tenantId={tenantId}
                    directoryId={directories?.[0]?.directoryId}
                    loadUsers={loadUsers}
                  />
                  <GroupsModal
                    groupsButtonProps={groupsButtonProps}
                    initSyncStatus={initSyncStatus}
                    tenantId={tenantId || ''}
                    key={'groupsMenu'}
                  />
                </>
              ) : (
                <div key={'emptyUserMenu'} />
              )}
            </>,
          ]}
        />
        <div ref={lastElementRef} data-testid="user-list-last-element">
          {isFetchingNextPage && (
            <Flex justify={'center'} style={{ padding: 15 }}>
              <Spin data-testid={'loading-indicator'} size="small" />
            </Flex>
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
